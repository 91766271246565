<template>
  <div class="btn-group-toggle" data-toggle="buttons" :class="{'disabled':isDisabledEdit}">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="btn btn-item"
      :class="[
        { active: value === option.value },
        {'disabled': isDisabledEdit},
      ]"
    >
      <input
        :disabled="isDisabledEdit"
        :name="name"
        :value="option.value"
        v-model="model"
        @blur="focused = false"
        @focus="focused = true"
        type="radio"
        :id="name"
        autocomplete="off"
        :checked="value === option.value"
      />
      <img
        :src="`/img/icons/stevie/${option.icon}.svg`"
        :alt="option.icon"
        class="icon"
      />
    </label>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  emits: ['change:modelValue'],
  name: "button-radio-group",
  props: {
    options: {
      type: Array,
      description:
        'Radio options. Should be an array of objects {value: "", label: ""}',
      default: () => [],
    },
    value: {
      type: String,
      description: "Radio value",
    },
    buttonClasses: {
      type: [String, Object],
      description: "Inner button css classes",
    },
    name: {
      type: String,
      required: true,
    },
    isDisabledEdit: {
      type:Boolean,
    },
    isPicture: {
      type: Boolean,
    }
  },
  data() {
    return {
      focused: false,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change:modelValue", val);
      },
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>
<style scoped lang="scss">
.btn-group-toggle{
  width: 88px;
  padding: 5px;
  height: 34px;
  display: flex;
  align-items: center;
  border: 1px solid #c6dcf5;
  box-shadow: 0px 1px 5px rgba(3,115,168,0.2);
  background-color: #ffffff;
  border-radius: 4px;
  .btn-item {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0!important;
    width: 27px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active{
      background-color: rgba(3, 115, 168, 0.2) !important;
    }
    .icon{
      width: 15px;
      height: 14px;

    }
  }
  &.disabled{
    background-color: #E8EEF2;
  }



}
</style>
